import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { KTSVG, toAbsoluteUrl } from '../../../helpers';
import { useLayout } from '../../core';
import { useThemeMode } from '../../../partials';

const SidebarLogo = () => {
  const { config } = useLayout();
  const { mode } = useThemeMode();
  const appSidebarDefaultMinimizeDesktopEnabled =
    config?.app?.sidebar?.default?.minimize?.desktop?.enabled;
  const appSidebarDefaultCollapseDesktopEnabled =
    config?.app?.sidebar?.default?.collapse?.desktop?.enabled;
  const toggleType = appSidebarDefaultCollapseDesktopEnabled
    ? 'collapse'
    : appSidebarDefaultMinimizeDesktopEnabled
    ? 'minimize'
    : '';
  const toggleState = appSidebarDefaultMinimizeDesktopEnabled ? 'active' : '';
  const appSidebarDefaultMinimizeDefault =
    config.app?.sidebar?.default?.minimize?.desktop?.default;
  return (
    <div className="app-sidebar-logo px-6 " id="kt_app_sidebar_logo">
      <Link to="/dashboard">
        {mode === 'dark' ? (
          <img
            alt="Logo"
            src={'/media/logos/cg-title-logo-white.png'}
            className="h-35px app-sidebar-logo-default"
          />
        ) : (
          <img
            alt="Logo"
            src={'/media/logos/cg-title-logo-dark.png'}
            className="h-35px app-sidebar-logo-default"
          />
        )}

        <img
          alt="Logo"
          src={'/media/logos/cg-logo.png'}
          className="h-20px app-sidebar-logo-minimize"
        />
      </Link>

      {(appSidebarDefaultMinimizeDesktopEnabled ||
        appSidebarDefaultCollapseDesktopEnabled) && (
        <div
          id="kt_app_sidebar_toggle"
          className={clsx(
            'app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate',
            { active: appSidebarDefaultMinimizeDefault }
          )}
          data-kt-toggle="true"
          data-kt-toggle-state={toggleState}
          data-kt-toggle-target="body"
          data-kt-toggle-name={`app-sidebar-${toggleType}`}
        >
          <KTSVG
            path="/media/icons/duotune/arrows/arr079.svg"
            className="svg-icon-2 rotate-180"
          />
        </div>
      )}
    </div>
  );
};

export { SidebarLogo };
